<template>
  <div v-if="this.user" class="avatar" :style="{ backgroundColor: color }">
    {{ avatarText }}
  </div>
  <div v-else>
    <img
      class="avatar"
      src="../../assets/Profile_Icon_Deleted.svg"
      data-holer-rendered="true"
    />
  </div>
</template>

<script>
const bluegreen = [
  "#0080A3",
  "#A1D35E",
  "#31BFD8",
  "#00A5A5",
  "#BBED78",
  "#4BD9F2",
  "#1ABFBF",
];

export default {
  name: "UserAvatar",
  props: {
    user: Object,
  },
  computed: {
    color() {
      return bluegreen[(this.user.id ?? 0) % bluegreen.length];
    },
    avatarText() {
      if (this.user === null) {
        return "X";
      }
      const allNames = this.user.name?.trim().split(" ") ?? "";
      const initials = allNames.reduce((acc, curr, index) => {
        if (index === 0 || index === allNames.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, "");
      return initials;
    },
  },
};
</script>

<style scoped>
.avatar {
  display: inline-block;
  color: white;
  font-size: 1.5em;
  font-weight: 600;
  text-align: center;
  min-width: 1.5em;
  height: 1.5em;
  border-radius: 100%;
}
</style>
