<!-- A button used to trigger a modal with the BaseModal component -->
<template>
  <button type="button" data-bs-toggle="modal" :data-bs-target="'#' + modalId">
    <slot />
  </button>
</template>

<script>
export default {
  name: "BaseModalButton",
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
};
</script>
