<template>
  <BaseModal :title="title" :modalId="modalId" @close="$emit('cancel')">
    <template #body>
      <slot name="body"></slot>
    </template>
    <template #footer>
      <button
        type="submit"
        :class="`btn ${confirmBtnClass} fw-bold`"
        :data-bs-dismiss="closeOnConfirm ? 'modal' : ''"
        :data-cy="`${modalId}ConfirmButton`"
        @click="$emit('confirm')"
      >
        {{ confirmBtnText }}
      </button>
      <button
        type="button"
        class="btn btn-light fw-bold"
        data-bs-dismiss="modal"
        :data-cy="`${modalId}CancelButton`"
        @click="$emit('cancel')"
      >
        {{ cancelBtnText }}
      </button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "@/components/ui/BaseModal.vue";

export default {
  name: "ConfirmationModal",
  components: { BaseModal },
  emits: ["confirm", "cancel"],
  props: {
    title: {
      type: String,
      default: "",
    },
    modalId: {
      type: String,
      required: true,
    },
    closeOnConfirm: {
      type: Boolean,
      default: true,
    },
    confirmBtnClass: {
      type: String,
      default: "pacific-button",
    },
    confirmBtnText: {
      type: String,
      default: "Confirm",
    },
    cancelBtnText: {
      type: String,
      default: "Cancel",
    },
  },
};
</script>
