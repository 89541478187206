<template>
  <div class="base-header">
    <div style="margin-bottom: 30px">
      <slot name="title">
        <h1 v-if="fetchedCardData" class="mb-3">{{ title }}</h1>
        <h1 v-else class="title-skeleton mb-3"></h1>
      </slot>
      <slot name="subtitle">
        <h6 v-if="fetchedCardData">{{ subtitle }}</h6>
        <h6 v-else class="subtitle-skeleton"></h6>
      </slot>
    </div>
    <div
      v-if="showcards"
      class="card border-0 shadow-sm container-fluid container-lg my-2"
    >
      <div class="card-body card-body-padding row g-3">
        <div class="col-lg card-body-margin">
          <Suspense>
            <template #default>
              <HeaderCard :fetchedCardData="fetchedCardData" :route="route1">
                <template #icon>
                  <slot name="icon1"></slot>
                </template>
                <template #heading>
                  <slot name="heading1"></slot>
                </template>
                <slot name="description1"></slot>
              </HeaderCard>
            </template>
            <template #fallback>
              <HeaderCardSkeleton>
                <template #icon>
                  <slot name="icon1"></slot>
                </template>
              </HeaderCardSkeleton>
            </template>
          </Suspense>
        </div>
        <div class="col-lg card-body-margin">
          <Suspense>
            <template #default>
              <HeaderCard :fetchedCardData="fetchedCardData" :route="route2">
                <template #icon>
                  <slot name="icon2"></slot>
                </template>
                <template #heading>
                  <slot name="heading2"></slot>
                </template>
                <slot name="description2"></slot>
              </HeaderCard>
            </template>
            <template #fallback>
              <HeaderCardSkeleton>
                <template #icon>
                  <slot name="icon2"></slot>
                </template>
              </HeaderCardSkeleton>
            </template>
          </Suspense>
        </div>
        <div class="col-lg card-body-margin">
          <Suspense>
            <template #default>
              <HeaderCard :fetchedCardData="fetchedCardData" :route="route3">
                <template #icon>
                  <slot name="icon3"></slot>
                </template>
                <template #heading>
                  <slot name="heading3"></slot>
                </template>
                <slot name="description3"></slot>
              </HeaderCard>
            </template>
            <template #fallback>
              <HeaderCardSkeleton>
                <template #icon>
                  <slot name="icon3"></slot>
                </template>
              </HeaderCardSkeleton>
            </template>
          </Suspense>
        </div>
        <div class="col-lg card-body-margin">
          <Suspense>
            <template #default>
              <HeaderCard :fetchedCardData="fetchedCardData" :route="route4">
                <template #icon>
                  <slot name="icon4"></slot>
                </template>
                <template #heading>
                  <slot name="heading4"></slot>
                </template>
                <slot name="description4"></slot>
              </HeaderCard>
            </template>
            <template #fallback>
              <HeaderCardSkeleton>
                <template #icon>
                  <slot name="icon4"></slot>
                </template>
              </HeaderCardSkeleton>
            </template>
          </Suspense>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import HeaderCardSkeleton from "../cards/HeaderCardSkeleton.vue";

export default {
  name: "ContentHeader",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: String,
    showcards: Boolean,
    fetchedCardData: {
      type: Boolean,
      default: true,
    },
    route1: Object,
    route2: Object,
    route3: Object,
    route4: Object,
  },
  components: {
    HeaderCard: defineAsyncComponent(() => import("../cards/HeaderCard.vue")),
    HeaderCardSkeleton,
  },
};
</script>

<style scoped>
.base-header {
  margin-top: -20px;
  padding-top: 30px;
  margin-bottom: 20px;
}
.base-header h1 {
  color: #434343;
}
.base-header h6 {
  font-family: montserrat, sans-serif;
}
.title-skeleton {
  content: " ";
  width: 20%;
  height: 1em;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg 1s infinite;
}
.subtitle-skeleton {
  width: 30%;
  height: 1em;
  line-height: 140%;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg 1s infinite;
}
.card-container {
  max-height: max-content;
}
.card {
  border-radius: 10px;
}
.card-body-padding {
  padding: 40px;
}
.card-body-margin {
  margin-left: 10px;
  margin-right: 20px;
}
</style>
