<template>
  <div>
    <!-- <label class="fw-bold mb-2">Filter Notes:</label> -->
    <div class="input-group">
      <input
        v-model="searchTerm"
        type="text"
        class="form-control"
        placeholder="Search notes by author, content, subject, or type (e.g. 'general', 'physician', 'facility')"
        aria-label="Search Term"
        aria-describedby="noteSearchAddOn"
      />
      <span
        class="input-group-text text-muted border-start-0 bg-white"
        id="noteSearchAddOn"
        ><i class="pi pi-search"
      /></span>
    </div>
    <!-- <div v-if="type === 'general'" class="d-flex mt-2">
      <button
        :class="[
          'badge btn me-2',
          showGeneral ? 'bg-info' : 'bg-light text-dark',
        ]"
        @click="showGeneral = !showGeneral"
      >
        <i class="pi pi-pencil"></i>
        General
      </button>
      <button
        :class="[
          'badge btn me-2',
          showPhysicians ? 'bg-info' : 'bg-light text-dark',
        ]"
        @click="showPhysicians = !showPhysicians"
      >
        <i class="fas fa-user-md" />
        Physician
      </button>
      <button
        :class="[
          'badge btn',
          showFacilities ? 'bg-info' : 'bg-light text-dark',
        ]"
        @click="showFacilities = !showFacilities"
      >
        <i class="fas fa-hospital" />
        Facility
      </button>
    </div> -->
  </div>
</template>

<script>
import { useLoggedInUserStore } from "@/store/loggedInUser";

export default {
  name: "NotesFilter",
  props: {
    filteredNotes: Array,
    npi: String,
    ccn: String,
  },
  emits: ["update:filteredNotes"],
  setup() {
    const user = useLoggedInUserStore();
    return { user };
  },
  data() {
    return {
      type: this.npi ? "physician" : this.ccn ? "facility" : "general",
      searchTerm: "",
      showGeneral: !this.npi && !this.ccn,
      showPhysicians: this.npi || (!this.npi && !this.ccn),
      showFacilities: this.ccn || (!this.npi && !this.ccn),
    };
  },
  computed: {
    scopedNotes() {
      return this.user.notes.filter((note) => {
        return (
          this.type === "general" ||
          (this.type === "physician" &&
            note.object_type === "Physician" &&
            note.object_key == this.npi) ||
          (this.type == "facility" &&
            note.object_type === "Facility" &&
            note.object_key == this.ccn)
        );
      });
    },
    toggledNotes() {
      return this.scopedNotes.filter((note) => {
        return (
          // check if note matches toggle selections
          (this.showGeneral && note.object_type === "General") ||
          (this.showPhysicians && note.object_type === "Physician") ||
          (this.showFacilities && note.object_type === "Facility")
        );
      });
    },
    newFilteredNotes() {
      const term = this.searchTerm.toLowerCase().trim();
      return this.toggledNotes.filter((note) => {
        return (
          // check if note matches search term
          (note.author?.name.toLowerCase() ?? "").includes(term) ||
          note.subject.toLowerCase().includes(term) ||
          note.content.toLowerCase().trim().includes(term) ||
          note.object_type.toLowerCase().includes(term)
        );
      });
    },
  },
  watch: {
    searchTerm: {
      handler: function () {
        this.$emit("update:filteredNotes", this.newFilteredNotes);
      },
      immediate: true,
    },
    user: {
      deep: true,
      handler: function () {
        this.$emit("update:filteredNotes", this.newFilteredNotes);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
button.bg-info:hover {
  color: white;
}
i.pi {
  font-size: 1em;
}
</style>
