<template>
  <main>
    <div>
      <BaseHeader
        subtitle="Select a feature to explore RepSignal"
        :showcards="false"
        data-cy="HomepageHeader"
      >
        <template #title>
          <h1 class="mb-3">
            <span style="color: black">Welcome Back,</span>
            {{ user.name }}
          </h1>
        </template>
      </BaseHeader>
      <DashboardLinks />
      <div class="mb-3 container-fluid container-lg">
        <div class="card border-0 news-card">
          <div class="card-body pb-3">
            <router-link
              :to="{ name: 'newsfeed' }"
              class="d-flex justify-content-start"
              style="
                font-family: montserrat, sans-serif;
                font-weight: 700;
                font-size: 24px;
                color: var(--S2NMainBlue);
                padding-left: 2rem;
              "
            >
              My News
            </router-link>
            <div class="cards">
              <NewsfeedCarousel />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { procedureModuleStore } from "@/store/procedureModule";
import { useLoggedInUserStore } from "@/store/loggedInUser";
import DashboardLinks from "./DashboardLinks.vue";
import NewsfeedCarousel from "@/components/cards/newfeed/NewsfeedCarousel.vue";
import BaseHeader from "@/components/layout/BaseHeader.vue";

export default {
  name: "HomeView",
  components: {
    DashboardLinks,
    NewsfeedCarousel,
    BaseHeader,
  },
  setup() {
    const procMod = procedureModuleStore();
    return { procMod };
  },
  data() {
    return {
      user: useLoggedInUserStore(),
      fetchedCardData: true,
    };
  },
  watch: {
    procMod: {
      deep: true,
      handler: function () {},
    },
  },
};
</script>

<style scoped>
.cards {
  color: black;
  text-align: left;
}
.icon {
  line-height: 40px;
  vertical-align: middle;
  font-size: 1.5rem;
  margin-left: 0.5rem;
}
.news-card {
  box-shadow: 0px 0px 6px 0px rgba(226, 224, 224, 0.889);
  border-radius: 10px;
}
</style>
