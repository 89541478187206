import createHttp from "@/services/http";

export async function getTweets() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/tweets`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getTrials() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/trials`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getPmas() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/pma`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getRecalls() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/recalls`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getFiveTenKs() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/five-ten-k`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getPublications() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/publications`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getNews() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/news`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getCustomNews() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/custom`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function getPhysicianUpdates() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/physicians`;
  const response = await createHttp().get(path);
  return response.data;
}

export async function updateLastViewed() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/last_viewed`;
  await createHttp().post(path);
}

export async function hideNewsItem(itemId) {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/newsfeed/hide/${itemId}`;
  await createHttp().post(path);
}

export async function unhideNewsItem(itemId) {
  const path = `${
    import.meta.env.VITE_FLASK_URL
  }/api/newsfeed/unhide/${itemId}`;
  await createHttp().post(path);
}

export async function queryTerritoryTrials(query, signal) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/trials/territory` + query,
    { signal }
  );
  return response.data;
}

export async function getTerritoryTrials(territoryId) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/trials/territory/${territoryId}`
  );
  return response.data;
}

export async function getRegionTrials(regionId) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/regions${
      regionId ? "/" + regionId : ""
    }/trials`
  );
  return response.data;
}

export async function getHospitalTrials(ccn) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/hospital/${ccn}/trials`
  );
  return response.data;
}

export async function getPhysicianTrials(npi) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/physician/${npi}/hospital/trials`
  );
  return response.data;
}
