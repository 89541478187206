<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
      fill="none"
    />
    <path
      :style="`fill: ${color}`"
      d="M32.6557 73.6094H26.3276C26.0463 73.6094 25.7651 73.3281 25.7651 72.9063V61.9375C25.6245 61.375 25.9057 61.0938 26.3276 61.0938H32.6557C32.937 61.0938 33.2182 61.375 33.2182 61.7969V72.7656C33.2182 73.1875 32.937 73.6094 32.6557 73.6094Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M67.9531 73.75H61.625C61.3438 73.75 61.0625 73.4688 61.0625 73.0469V42.1094C61.0625 41.6875 61.3438 41.4062 61.625 41.4062H67.9531C68.2344 41.4062 68.5156 41.6875 68.5156 42.1094V73.0469C68.6563 73.4688 68.375 73.75 67.9531 73.75Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M56.1406 73.75H49.8125C49.5312 73.75 49.25 73.4687 49.25 73.0469V50.2656C49.25 49.8438 49.5312 49.5625 49.8125 49.5625H56.1406C56.4219 49.5625 56.7031 49.8438 56.7031 50.2656V72.9062C56.8437 73.4687 56.5625 73.75 56.1406 73.75Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M44.3281 73.75H38C37.7187 73.75 37.4375 73.4688 37.4375 73.0469V56.1719C37.4375 55.75 37.7187 55.4688 38 55.4688H44.3281C44.6094 55.4688 44.8906 55.75 44.8906 56.1719V73.0469C45.0312 73.4688 44.75 73.75 44.3281 73.75Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M72.875 17.7812C72.5938 17.5 72.1719 17.3594 71.75 17.5L56.7031 21.4375C56.1406 21.5781 55.8594 22 55.7188 22.4219C55.7188 22.5625 55.7188 22.7031 55.7188 22.8437C55.7188 23.125 55.8594 23.4062 56.1406 23.6875C56.1406 23.6875 57.8281 25.0937 58.9531 25.9375C57.6875 27.3437 29.8438 48.2969 24.5 52.6562C24.2188 52.7969 23.9375 52.9375 23.7969 53.2187C23.7969 53.2187 23.7969 53.2187 23.6562 53.3594C23.6562 53.3594 23.6562 53.3594 23.6562 53.5C23.5156 53.6406 23.5156 53.9219 23.5156 54.2031C23.5156 55.0469 24.0781 55.75 24.9219 55.75C25.0625 55.75 25.2031 55.75 25.2031 55.75H25.3437C27.1719 54.625 64.7187 33.1094 66.6875 32.125C67.8125 32.9687 69.6406 34.375 69.6406 34.375C70.0625 34.6562 70.625 34.7969 71.0469 34.5156C71.4688 34.375 71.75 33.9531 71.8906 33.3906L73.4375 18.9062C73.4375 18.4844 73.2969 18.0625 72.875 17.7812ZM69.5 30.8594C68.5156 30.1562 67.6719 29.3125 67.6719 29.3125C67.1094 28.8906 66.2656 28.8906 65.8438 29.4531C65.8438 29.4531 30.8281 50.4062 30.2656 50.6875C29.5625 51.1094 29.8438 50.6875 29.8438 50.6875L61.9062 26.5C62.1875 26.2187 62.1875 25.9375 62.1875 25.5156C62.1875 25.2344 61.9062 24.8125 61.7656 24.6719C61.7656 24.6719 60.7812 23.9687 59.9375 23.125C62.8906 22.4219 68.375 20.875 70.625 20.3125C70.3438 22.7031 69.7812 28.0469 69.5 30.8594Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: String,
  },
};
</script>
