<template>
  <div>
    <!-- Need to use CSS instead of Vue to handle Carousel reactivity and responsiveness to prevent issues with card order. -->
    <template v-if="newsStore.fetchedNews">
      <div class="d-none d-xxl-block">
        <Carousel
          :value="newsItems"
          :numVisible="3"
          :numScroll="1"
          indicatorsContentClass="d-none"
        >
          <template #item="slotProps">
            <NewsfeedCard
              :newsItem="slotProps.data"
              class="m-1"
              :inCarousel="true"
              @block-handle="blockHandle"
              @set-block-handle="setBlockHandle"
            />
          </template>
        </Carousel>
      </div>
      <div class="d-none d-sm-block d-xxl-none">
        <Carousel
          :value="newsItems"
          :numVisible="2"
          :numScroll="1"
          indicatorsContentClass="d-none"
        >
          <template #item="slotProps">
            <NewsfeedCard
              :newsItem="slotProps.data"
              class="m-2"
              :inCarousel="true"
              @block-handle="blockHandle"
              @set-block-handle="setBlockHandle"
            />
          </template>
        </Carousel>
      </div>
      <div class="d-block d-sm-none">
        <Carousel
          :value="newsItems"
          :numVisible="1"
          :numScroll="1"
          indicatorsContentClass="d-none"
        >
          <template #item="slotProps">
            <NewsfeedCard
              :newsItem="slotProps.data"
              class="m-2"
              :inCarousel="true"
              @block-handle="blockHandle"
              @set-block-handle="setBlockHandle"
            />
          </template>
        </Carousel>
      </div>
    </template>
    <div class="row g-2" v-else>
      <div v-for="n in 3" :key="n" class="col-12 col-md-4 col-xxl-4">
        <NewsfeedSkeleton />
      </div>
    </div>
  </div>
  <Teleport to="body">
    <!-- Block Account Confirmation Modal -->
    <ConfirmationModal
      title="Block Account"
      modalId="blockTwitterAccount"
      confirmBtnText="Block"
      confirmBtnClass="btn-danger"
      @confirm="blockHandle"
    >
      <template #body>
        <h6>
          Are you sure you want to block this account? You will no longer see
          tweets from @{{ handleToBlock }}.
        </h6>
      </template>
    </ConfirmationModal>
  </Teleport>
</template>

<script>
import { useNewsStore } from "@/store/news";
import { useLoggedInUserStore } from "@/store/loggedInUser";
import Carousel from "primevue/carousel";
import NewsfeedCard from "./NewsfeedCard.vue";
import NewsfeedSkeleton from "./NewsfeedSkeleton.vue";
import ConfirmationModal from "@/components/ui/ConfirmationModal.vue";

const MILLISECONDS_PER_DAY = 86400000;
const NEWS_WEIGHTS = {
  pma: 1,
  trial: 1,
  "510k": 2,
  tweet: 1.2,
  publication: 2.5,
  physician: 3,
  recall: 2,
  news: 1.5,
};

export default {
  name: "NewsfeedCarousel",
  data() {
    return {
      handleToBlock: "",
    };
  },
  props: {
    maxItems: {
      type: Number,
      default: 5,
    },
  },
  components: {
    Carousel,
    NewsfeedCard,
    NewsfeedSkeleton,
    ConfirmationModal,
  },
  setup() {
    const newsStore = useNewsStore();
    const user = useLoggedInUserStore();
    return { newsStore, user };
  },
  computed: {
    blockedTwitterHandles() {
      return this.user.blocked_twitter_handles;
    },
    newsItems() {
      return this.newsStore.allNews
        .filter(
          (item) =>
            !item.is_hidden && this.handleNotBlocked(item.twitter_handle)
        )
        .slice(0, 20)
        .map((item) => ({
          ...item,
          score: this.getNewsScore(item),
        }))
        .sort((item1, item2) => item1.score - item2.score)
        .slice(0, this.maxItems);
    },
  },
  methods: {
    handleNotBlocked(twitterHandle) {
      return !this.blockedTwitterHandles.includes(twitterHandle);
    },
    getNewsScore(item) {
      const currentTime = new Date().getTime();
      return (
        (currentTime - Date.parse(item.date)) /
        MILLISECONDS_PER_DAY /
        NEWS_WEIGHTS[item.type]
      );
    },
    async blockHandle() {
      await this.user.blockHandle(this.handleToBlock);
    },
    setBlockHandle(handle) {
      this.handleToBlock = handle;
    },
  },
};
</script>
