<template>
  <div :class="['card h-100', colorClass]">
    <div class="card-body">
      <div v-if="hasSlotContent(this.$slots.icon)" class="card-icon mb-1">
        <slot name="icon"></slot>
      </div>
      <h2 class="title-skeleton mb-3"></h2>
      <p class="text-skeleton"></p>
    </div>
  </div>
</template>

<script>
import { hasSlotContent } from "@/services/hasSlotContent";

export default {
  props: {
    colorClass: String,
  },
  setup() {
    return {
      hasSlotContent,
    };
  },
};
</script>

<style scoped>
.card-icon {
  width: 3rem;
}
.title-skeleton {
  content: " ";
  width: 80%;
  height: 2rem;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg 1s infinite;
}
.text-skeleton {
  width: 80%;
  height: 1rem;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}
</style>
