<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
      fill="none"
    />
    <path
      :style="`fill: ${color}`"
      d="M50.0898 50.9375C45.0006 50.9375 40.9827 46.7857 40.9827 41.8304C40.9827 36.7411 45.1345 32.7233 50.0898 32.7233C55.0452 32.7233 59.197 36.875 59.197 41.8304C59.331 46.7857 55.1792 50.9375 50.0898 50.9375ZM50.0898 23.75C39.7773 23.75 31.3398 32.1875 31.3398 42.5C31.3398 45.3125 32.0095 48.125 33.2148 50.6696C34.956 54.1518 40.0452 62.7233 45.4023 71.6964L47.947 76.1161C48.3488 76.7857 49.1523 77.3214 49.956 77.3214C50.7595 77.3214 51.5631 76.9196 51.9648 76.1161L54.5095 71.6964C59.8667 62.7233 64.822 54.1518 66.5631 50.6696C66.5631 50.6696 66.5631 50.6696 66.5631 50.5358C67.7685 47.9911 68.4381 45.1786 68.4381 42.3661C68.8398 32.1875 60.4023 23.75 50.0898 23.75Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: String,
  },
};
</script>
