import createHttp from "@/services/http";

export async function getRegions(id = null) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/regions${id ? `/${id}` : ""}`
  );
  return response.data;
}

export async function getRegionProcedureVolumes(id = null) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/regions${
      id ? `/${id}` : ""
    }/procedure_volumes`
  );
  return response.data;
}

export async function getRegionPhysicianVolumes(id = null) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/regions${
      id ? `/${id}` : ""
    }/physician_volumes`
  );
  return response.data;
}

export async function addRegion(nickname, territories, managers) {
  const regionData = {
    nickname: nickname,
    territories: territories,
    managers: managers,
  };
  const response = await createHttp().post(
    `${import.meta.env.VITE_FLASK_URL}/api/regions/add`,
    regionData
  );

  if (response.status !== 200) {
    throw new Error(response);
  }

  return response.data;
}

export async function editRegion(id, nickname, territories, managers) {
  const regionData = {
    nickname: nickname,
    territories: territories,
    managers: managers,
  };
  const path = `${import.meta.env.VITE_FLASK_URL}/api/regions/${id}/edit`;
  const response = await createHttp().patch(path, regionData);

  if (response.status !== 200) {
    throw new Error(response);
  }

  return response.data;
}

export async function assignRegion(regionId, managerId) {
  const response = await createHttp().patch(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/regions/${regionId}/assign?manager_id=${managerId}`
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}

export async function unassignRegion(managerId) {
  const response = await createHttp().patch(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/regions/managers/${managerId}/unassign`
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}

export async function deleteRegion(regionId) {
  const response = await createHttp().delete(
    `${import.meta.env.VITE_FLASK_URL}/api/regions/${regionId}/delete`
  );

  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}
