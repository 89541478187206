// store/loggedInUser.js
import axios from "axios";
import * as configCat from "configcat-js";
import { defineStore } from "pinia";
import { useNewsStore } from "./news";
import { useMapStore } from "./map";
import { useHospitalStore } from "./hospital";
import { usePhysicianStore } from "./physician";
import { procedureModuleStore } from "./procedureModule";
import {
  getNotes,
  addNote,
  editNote,
  markNoteAsRead,
  deleteNote,
} from "@/services/apiCalls/notes";
import createHttp from "@/services/http";
import router from "@/router";

export const useLoggedInUserStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: "loggedInUser",
  state: () => ({
    loggedInAsAdmin: false,
    token: "",
    expiration: Date.now(),
    user_id: null,
    email: "",
    name: "",
    company: null,
    region_id: null,
    accessibleRegions: [],
    accessibleTerritories: [],
    territory_id: 0,
    user_type: "",
    is_admin: false,
    is_deleted: false,
    viewed_license_at: "",
    error: "",
    notes: [],
    assigned_empty_region: false,
    blocked_twitter_handles: [],
    can_enrich: false,
  }),
  persist: true,
  getters: {
    acceptedEula() {
      return this.viewed_license_at !== "" && this.viewed_license_at !== null;
    },
    isLeader() {
      return this.user_type === "leader";
    },
    isRep() {
      return this.user_type === "rep";
    },
    hasRegion() {
      return !!this.region_id;
    },
    hasTerritory() {
      return !!this.territory_id;
    },
  },
  actions: {
    async blockHandle(handle) {
      const http = createHttp();
      await http.post(
        `${
          import.meta.env.VITE_FLASK_URL
        }/api/user/block_twitter_account?handle=${handle}`
      );
      this.blocked_twitter_handles.push(handle);
    },
    async unblockHandle(handle) {
      const http = createHttp();
      await http.post(
        `${
          import.meta.env.VITE_FLASK_URL
        }/api/user/unblock_twitter_account?handle=${handle}`
      );
      this.blocked_twitter_handles = this.blocked_twitter_handles.filter(
        (blockedHandle) => blockedHandle !== handle
      );
    },
    async login(model) {
      try {
        this.is_deleted = false;
        const http = createHttp(false); // unsecured
        const result = await http
          .post(`${import.meta.env.VITE_FLASK_URL}/api/auth`, model)
          .catch((error) => {
            if (error.response.data.deleted === true) {
              this.is_deleted = true;
            }
          });

        if (this.is_deleted) {
          this.error = "User Deleted";
        } else if (result.data.token.length > 0) {
          this.getUserInfoFrom(result);
          this.getNotes();
        } else {
          this.error = "Authentication Failed";
        }
      } catch {
        this.error = "Login Failed";
      }
    },
    async checkIfLoggedInAsAdmin() {
      if (this.isAuthenticated()) {
        return;
      }
      try {
        const response = await createHttp().get(
          `${import.meta.env.VITE_FLASK_URL}/api/current_user`
        );
        this.getUserInfoFrom(response);
        this.loggedInAsAdmin = true;
      } catch (error) {
        this.loggedInAsAdmin = false;
      }
    },
    isAuthenticated() {
      return (
        this.loggedInAsAdmin ||
        (this.token.length > 0 && Date.parse(this.expiration) > Date.now())
      );
    },
    async logActivity(userPath) {
      try {
        const http = createHttp();
        await http.post(`${import.meta.env.VITE_FLASK_URL}/api/user/activity`, {
          user_path: userPath,
        });
      } catch {
        // do nothing
      }
    },
    async acceptEula() {
      try {
        const path = `${
          import.meta.env.VITE_FLASK_URL
        }/api/license_and_disclaimers`;
        const result = await createHttp().post(path);
        this.viewed_license_at = result.data.viewed_license_at;
        router.push("/");
      } catch (err) {
        this.error = "EULA Acceptance Failed";
      }
    },
    async validateTempToken(tempToken) {
      const http = axios.create({
        headers: { Authorization: `bearer ${tempToken}` },
      });

      const response = await http.get(
        `${import.meta.env.VITE_FLASK_URL}/api/user/validate_temp_token`
      );

      this.getUserInfoFrom(response);
    },
    async updateInfo() {
      const response = await createHttp().get(
        `${import.meta.env.VITE_FLASK_URL}/api/user/info`
      );
      this.getUserInfoFrom(response);
    },
    getUserInfoFrom(response) {
      const data = response.data;

      this.user_id = data.id;
      this.name = data.name;
      this.email = data.email;
      this.company = data.company;
      this.region_id = data.region_id;
      this.accessibleRegions = data.accessible_regions;
      this.accessibleTerritories = data.accessible_territories;
      this.territory_id = data.territory_id;
      this.is_admin = data.is_admin;
      this.user_type = data.user_type;
      this.viewed_license_at = data.viewed_license_at;
      this.updateLastViewedNewsfeed(data.last_newsfeed_view);
      this.blocked_twitter_handles = data.blocked_twitter_handles;
      this.can_enrich = data.can_enrich;

      if (data.token) {
        this.token = data.token;
      }
      if (data.expiration) {
        /**
         * data.expiration is UTC, but the `Date` constructor interprets any datetime value with
         * no timezone info as local time.
         * https://maggiepint.com/2017/04/11/fixing-javascript-date-web-compatibility-and-reality
         *
         * Need to offset data.expiration so that it matches local time, and then pass it into the
         * `Date` constructor, which will then be UTC.
         */
        const now = new Date();
        const timezoneOffsetMS = now.getTimezoneOffset() * 60 * 1000;
        const expirationLocal = data.expiration - timezoneOffsetMS;
        this.expiration = new Date(expirationLocal);
      }
    },
    updateLastViewedNewsfeed(date) {
      const newsStore = useNewsStore();
      newsStore.lastViewedNewsfeed = date;
    },
    async getNotes() {
      try {
        this.notes = await getNotes();
        return true;
      } catch (error) {
        return false;
      }
    },
    async createNote(type, content, subscribers, key = null) {
      const noteData = {
        note_content: content,
        note_type: type,
        note_subscribers: subscribers.map((subscriber) => subscriber.id),
      };

      if (key) {
        noteData.note_key = key;
      }

      try {
        const response = await addNote(noteData);
        this.notes.unshift(response);
        return true;
      } catch (error) {
        return false;
      }
    },
    async editNote(note, newContent, subscribers) {
      const patchData = {
        id: note.id,
        note_content: newContent,
        note_subscribers: subscribers.map((subscriber) => subscriber.id),
      };

      try {
        const response = await editNote(note.id, patchData);
        note.content = newContent;
        note.subscribers = subscribers;
        note.updated_at = response.updated_at;
        return true;
      } catch (error) {
        return false;
      }
    },
    async markNoteAsRead(note) {
      try {
        await markNoteAsRead(note.id);
        note.has_been_read = true;
        return true;
      } catch {
        return false;
      }
    },
    async deleteNote(noteToDelete) {
      try {
        await deleteNote(noteToDelete.id);
        this.notes = this.notes.filter((note) => note.id !== noteToDelete.id);
        return true;
      } catch {
        return false;
      }
    },
    async getFeatureFlag(feature) {
      if (!(this.company && this.email)) {
        await this.updateInfo();
      }
      const configCatClient = configCat.getClient(
        import.meta.env.VITE_CONFIGCAT_SDK
      );
      const user = new configCat.User(this.user_id, this.email, null, {
        company: this.company,
      });
      return configCatClient.getValueAsync(feature, true, user);
    },
    redirect(path = "/") {
      router.push(path);
    },
    logout() {
      const newsStore = useNewsStore();
      const mapStore = useMapStore();
      const hospitalStore = useHospitalStore();
      const physicianStore = usePhysicianStore();
      const procModStore = procedureModuleStore();

      this.$reset();
      newsStore.$reset();
      mapStore.$reset();
      hospitalStore.$reset();
      physicianStore.$reset();
      procModStore.$reset();

      router.push("/");
    },
  },
});
