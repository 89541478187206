<template>
  <div class="card mb-2">
    <div class="card-body px-4">
      <div class="subtitle-skeleton mb-3"></div>
      <div class="link-skeleton mb-1"></div>
      <div v-for="n in 2" :key="n" class="text-skeleton mb-1"></div>
      <div class="text-skeleton mb-3"></div>
      <div class="link-skeleton mb-1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsfeedSkeleton",
};
</script>

<style scoped>
.subtitle-skeleton {
  width: 30%;
  height: 1rem;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg 1s infinite;
}
.text-skeleton {
  width: 80%;
  height: 1rem;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg 1s infinite;
}
.link-skeleton {
  width: 40%;
  height: 1rem;
  border-radius: 10px;
  opacity: 0.5;
  animation: pulse-bg-blue 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }
  50% {
    background-color: #d0d0d0;
  }
  100% {
    background-color: #ddd;
  }
}
@keyframes pulse-bg-blue {
  0% {
    background-color: rgb(122, 216, 245);
  }
  50% {
    background-color: rgb(111, 196, 222);
  }
  100% {
    background-color: rgb(122, 216, 245);
  }
}
</style>
