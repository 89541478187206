import { defineStore } from "pinia";
import { shallowRef } from "vue";
import {
  getHospitals,
  getHospitalTrialFlags,
} from "@/services/apiCalls/hospital";
import { handleAbort } from "@/services/http";

export const useHospitalStore = defineStore({
  id: "hospital",
  state: () => ({
    hospitals: shallowRef([]), // use shallow reactivity to reduce performance overhead in case of many hospitals (https://vuejs.org/guide/best-practices/performance.html#reduce-reactivity-overhead-for-large-immutable-structures)
    hospTrialFlags: {}, // ccn -> true if has trials, else false
    fetchedHosps: false,
    fetchedHospTrialFlags: false,
    abortController: null,
  }),
  persist: true,
  actions: {
    async getHospitals({ signal }) {
      this.hospitals = [];
      this.fetchedHosps = false;
      try {
        this.hospitals = await getHospitals(signal);
        this.fetchedHosps = true;
      } catch (error) {
        if (error.name === "CanceledError") return;
        console.error(error);
      }
    },
    async getHospTrialFlags({ signal }) {
      this.fetchedHospTrialFlags = false;
      try {
        this.hospTrialFlags = await getHospitalTrialFlags(signal);
      } catch (error) {
        if (error.name === "CanceledError") return;
        console.error(error);
      }
      this.fetchedHospTrialFlags = true;
    },
    async getData() {
      this.abortController = handleAbort(this.abortController);
      this.getHospitals(this.abortController);
      this.getHospTrialFlags(this.abortController);
    },
  },
});
