<template>
  <div
    :class="['card', isRead ? '' : 'card-new', showEditForm ? 'shadow' : '']"
    @mouseover="markAsRead"
  >
    <div class="card-body">
      <div :class="['d-flex align-items-start', showEditForm ? 'mb-3' : '']">
        <UserAvatar :user="note.author" class="me-3" />
        <div class="flex-fill">
          <div class="d-flex align-items-start mb-1">
            <h6 class="card-title fw-bold">
              {{ this.note.author?.name ?? "Deleted User" }}
            </h6>
            <Transition name="bounce">
              <span class="badge bg-info p-1 ms-1" v-if="isUserNote">you</span>
              <span class="badge badge-new p-1 ms-1" v-else-if="!isRead"
                >new</span
              >
            </Transition>
            <small class="card-text text-muted ms-auto"
              >{{ formatDate(note.updated_at) ?? ""
              }}{{ edited ? " (Edited)" : "" }}</small
            >
          </div>
          <p v-show="!showEditForm" class="card-text">
            {{ content }}
          </p>
          <!-- Link and buttons -->
          <div v-if="!showEditForm" class="d-flex align-items-center">
            <router-link
              v-if="['Physician', 'Facility'].includes(note.object_type)"
              :to="{
                name:
                  note.object_type === 'Physician' ? 'physician' : 'hospital',
                params:
                  note.object_type === 'Physician'
                    ? { npi: note.object_key }
                    : { ccn: note.object_key },
              }"
              :id="'noteCardLink' + note.id"
              class="fw-bold small"
              ><i
                :class="[
                  'fas me-2',
                  note.object_type === 'Physician'
                    ? 'fa-user-md'
                    : 'fa-hospital',
                ]"
              />{{ note.subject }}</router-link
            >
            <div
              v-if="isUserNote || (userIsLeader && authorDeleted)"
              class="ms-auto"
            >
              <!-- Edit -->
              <button
                v-if="isUserNote"
                class="btn btn-edit btn-light me-2"
                @click="showEditForm = true"
              >
                <i class="pi pi-pencil me-1" />
                <small class="d-none d-sm-inline fw-bold">Edit</small>
              </button>
              <!-- Delete -->
              <BaseModalButton
                class="btn btn-delete btn-light"
                :modalId="deleteModal"
                data-bs-dismiss="modal"
                @click="$emit('delete-note')"
              >
                <i class="pi pi-trash me-1" />
                <small class="d-none d-sm-inline fw-bold">Delete</small>
              </BaseModalButton>
            </div>
          </div>
        </div>
      </div>
      <!-- Edit -->
      <div v-show="showEditForm">
        <form
          :id="'editNoteForm' + note.id"
          class="d-flex align-items-center needs-validation"
          @submit.prevent="editNote"
          novalidate
        >
          <div class="col me-3">
            <textarea
              class="form-control"
              :id="'editNoteTextarea' + note.id"
              v-model="content"
              rows="3"
              required
            ></textarea>
            <div class="invalid-feedback">Your note cannot be blank.</div>
            <ShareBar
              v-if="isUserNote"
              v-model:subscribers="subscribers"
              :contacts="contacts"
              placeholder="Share your note with..."
              class="mt-3"
            />
          </div>
          <button type="submit" class="btn btn-info fw-bold text-white">
            Save
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useLoggedInUserStore } from "@/store/loggedInUser";
import ShareBar from "@/components/layout/ShareBar.vue";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import BaseModalButton from "@/components/buttons/BaseModalButton.vue";

export default {
  name: "NoteCard",
  components: {
    ShareBar,
    UserAvatar,
    BaseModalButton,
  },
  props: {
    note: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    deleteModal: String,
  },
  emits: ["link-clicked", "delete-note"],
  setup() {
    const user = useLoggedInUserStore();
    return { user };
  },
  data() {
    return {
      isRead: true,
      content: "",
      edited: false,
      subscribers: [],
      showEditForm: false,
    };
  },
  methods: {
    async editNote() {
      if (!this.content) {
        return;
      }

      try {
        await this.user.editNote(this.note, this.content, this.subscribers);
        this.showEditForm = false;
      } catch (error) {
        console.log(error);
      }
    },
    markAsRead() {
      if (this.isRead) {
        return;
      }
      this.isRead = true;
      this.user.markNoteAsRead(this.note);
    },
    formatDate(dateString) {
      var date = new Date(dateString);
      return date.toLocaleDateString("en-US");
    },
  },
  computed: {
    isUserNote() {
      return this.note.author?.id === this.user.user_id;
    },
    authorDeleted() {
      return this.note.author === null;
    },
    userIsLeader() {
      return this.user.user_type === "leader";
    },
  },
  mounted() {
    // Bootstrap form validation
    const form = document.querySelector("#editNoteForm" + this.note.id);
    form.addEventListener(
      "submit",
      function (event) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
      },
      false
    );
  },
  watch: {
    note: {
      deep: true,
      immediate: true,
      handler: function (newNote) {
        this.isRead = newNote.has_been_read;
        this.edited = newNote.created_at !== newNote.updated_at;

        // only refresh note content/subscribers if user is not editing
        if (!this.showEditForm) {
          this.content = newNote.content;
          this.subscribers = newNote.subscribers.filter(
            (user) => user.id !== this.user.user_id
          );
        }

        this.$nextTick(() => {
          // emit when physician/facility link is clicked (can't add v-on directly to RouterLink)
          const link = document.querySelector("#noteCardLink" + newNote.id);
          if (link) {
            link.addEventListener("click", () => this.$emit("link-clicked"));
          }
        });
      },
    },
  },
};
</script>

<style scoped>
.card-new {
  border-left: 3px solid var(--main-green);
}

i.pi {
  font-size: 0.8em;
}

.badge-new {
  background-color: var(--main-green);
}

.btn-edit:hover {
  color: var(--pacific-blue);
}
.btn-delete:hover {
  color: red;
}

textarea {
  resize: none;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
