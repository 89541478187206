<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
      fill="none"
    />
    <path
      :style="`fill: ${color}`"
      d="M35.6823 47.2271C34.8869 47.2271 34.205 47.2271 33.4096 47.2271C32.1596 47.2271 31.3641 48.477 31.8187 49.6133C32.0459 50.2951 32.7278 50.7498 33.5232 50.7498C35.9096 50.7498 38.4096 50.7498 40.7959 50.7498C41.2505 49.4998 41.8187 48.3634 42.6141 47.2271C40.3414 47.2271 37.955 47.2271 35.6823 47.2271Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M55.2278 38.8182C55.0005 38.0228 54.3187 37.4546 53.4096 37.4546C50.1141 37.4546 46.8187 37.4546 43.5232 37.4546C40.9096 37.4546 38.2959 37.4546 35.6823 37.4546C34.8869 37.4546 34.205 37.4546 33.4096 37.4546C32.1596 37.4546 31.3641 38.7046 31.8187 39.841C32.0459 40.5228 32.7278 40.9773 33.5232 40.9773C38.9778 40.9773 44.3187 40.9773 49.7732 40.9773C51.4778 40.1819 53.2959 39.6137 55.1141 39.3864C55.2278 39.1591 55.2278 38.9319 55.2278 38.8182Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M36.7051 57C35.6824 57 34.6597 57 33.6369 57C33.4097 57 33.0688 57.1136 32.8415 57.2273C32.046 57.5682 31.7051 58.3636 31.9324 59.1591C32.046 59.9545 32.8415 60.5227 33.6369 60.5227C35.6824 60.5227 37.8415 60.5227 39.8869 60.5227C40.0006 60.5227 40.1142 60.5227 40.1142 60.5227C39.8869 59.3864 39.7733 58.25 39.7733 57C38.6369 57 37.6142 57 36.7051 57Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M44.3182 68.9318C39.7727 68.9318 35.2273 68.9318 30.6818 68.9318C29.4318 68.9318 28.5227 68.1364 28.5227 66.7727C28.5227 55.4091 28.5227 43.9318 28.5227 32.5682C28.5227 32.3409 28.5227 32.2273 28.5227 32C28.75 31.0909 29.5455 30.5227 30.6818 30.5227C39.2045 30.5227 47.7273 30.5227 56.25 30.5227C56.4773 30.5227 56.7045 30.5227 56.8182 30.6364C57.7273 30.8636 58.2955 31.6591 58.4091 32.6818C58.4091 34.8409 58.4091 37.1136 58.4091 39.2727C59.6591 39.2727 60.7955 39.5 61.9318 39.8409V39.7273C61.9318 37.5682 61.9318 35.5227 61.9318 33.3636C61.9318 32.5682 61.9318 31.8864 61.9318 31.0909C61.9318 30.2955 61.7045 29.5 61.1364 28.8182C60.2273 27.4545 59.0909 27 57.7273 27C48.1818 27 38.75 27 29.2045 27C29.0909 27 28.9773 27 28.8636 27C27.0455 27 25.5682 28.25 25.1136 30.0682C25.1136 30.1818 25 30.4091 25 30.5227V68.8182C25 68.9318 25 69.0455 25 69.0455C25.3409 70.9773 26.9318 72.3409 28.8636 72.4545C29.6591 72.4545 30.5682 72.4545 31.3636 72.4545C31.7045 72.4545 32.0455 72.4545 32.3864 72.4545C36.9318 72.4545 41.5909 72.4545 46.1364 72.4545C46.25 72.4545 46.3636 72.4545 46.4773 72.4545C46.9318 72.4545 47.2727 72.2273 47.6136 72C46.4773 71.0909 45.3409 70.0682 44.3182 68.9318Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M72.9554 69.3863C72.5009 68.9317 72.0463 68.4772 71.5918 68.0226C70.2281 66.659 68.7509 65.1817 67.3872 63.8181C67.2736 63.7045 67.2736 63.5908 67.3872 63.3636C67.7281 62.7954 68.0691 62.1136 68.41 61.5454C70.0009 58.1363 70.0009 54.6136 68.2963 51.3181C66.2509 47.2272 62.8418 45.0681 58.2963 44.7272C55.3418 44.4999 52.6145 45.409 50.3418 47.2272C51.3645 47.2272 52.5009 47.2272 53.5236 47.2272C54.4327 47.2272 55.1145 47.6817 55.3418 48.4772C56.1372 48.2499 56.9327 48.1363 57.7281 48.1363C62.3872 48.1363 66.1372 51.8863 66.1372 56.5454C66.1372 61.2045 62.3872 64.9545 57.7281 64.9545C53.0691 64.9545 49.3191 61.2045 49.3191 56.5454C49.3191 54.2726 50.2281 52.2272 51.7054 50.7499C50.2281 50.7499 48.8645 50.7499 47.3872 50.7499C45.91 53.2499 45.5691 56.0908 46.2509 59.159C46.8191 61.8863 48.1827 64.2726 50.4554 66.0908C52.3872 67.6817 54.66 68.5908 57.2736 68.4772C58.7509 68.4772 60.2281 68.1363 61.7054 67.7954C62.8418 67.4545 63.9781 66.8863 65.0009 66.2045C65.1145 66.3181 65.1145 66.3181 65.2281 66.4317C66.7054 67.909 68.0691 69.2726 69.5463 70.7499C70.0009 71.2045 70.4554 71.659 70.91 72.1136C71.0236 72.2272 71.2509 72.4545 71.4781 72.4545C72.16 72.6817 73.1827 72.3408 73.5236 71.659C73.6372 70.8636 73.5236 70.0681 72.9554 69.3863Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M47.1582 50.7498C48.6355 50.7498 49.9991 50.7498 51.4764 50.7498C52.4991 49.7271 53.7491 48.9316 55.1127 48.4771C54.8855 47.6816 54.2037 47.2271 53.2946 47.2271C52.2718 47.2271 51.1355 47.2271 50.1127 47.2271C49.9991 47.3407 49.9991 47.3407 49.8855 47.4543C48.7491 48.3634 47.84 49.4998 47.1582 50.7498Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: String,
  },
};
</script>
