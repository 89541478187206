import createHttp from "@/services/http";

export async function getProcedureModules() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/procedure/modules`;
  const result = await createHttp().get(path);
  return result.data;
}

export async function changeProcedureModule(module_id) {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/procedure/modules`;
  const result = await createHttp().post(path, { id: module_id });
  return result.data;
}

export async function getSignaledPhysicians() {
  const http = createHttp();
  const result = await http.get(
    `${
      import.meta.env.VITE_FLASK_URL
    }/api/procedure/modules/signaled_physicians`
  );
  return result.data;
}

export async function getProcedureModuleInfo() {
  var path = `${import.meta.env.VITE_FLASK_URL}/api/procedure/modules/info`;
  const result = await createHttp().get(path);
  return result.data;
}
