<template>
  <div aria-live="polite" aria-atomic="true" class="position-relative">
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div
        v-for="note in unreadNotes"
        :key="note.id"
        class="toast align-items-center"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div class="d-flex">
          <div
            class="toast-body d-flex align-items-center"
            data-bs-toggle="modal"
            data-bs-target="#notesModal"
          >
            <UserAvatar :user="note.author" class="me-2" />
            <div>
              <div class="text-muted mb-1">
                <strong>{{ note.author?.name ?? "Deleted User" }}</strong>
                shared a note
                <span v-if="note.subject"
                  >about <strong>{{ note.subject }}</strong></span
                >
                with you.
              </div>
              <!-- <div class="text-muted small">
                {{ getTimeEllapsed(note.updated_at) }}
              </div> -->
            </div>
          </div>
          <button
            type="button"
            class="btn-close me-2 m-auto"
            data-bs-dismiss="toast"
            aria-label="Close"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "bootstrap";
import { useLoggedInUserStore } from "@/store/loggedInUser";
import UserAvatar from "@/components/ui/UserAvatar.vue";

export default {
  name: "NoteToasts",
  components: {
    UserAvatar,
  },
  setup() {
    const user = useLoggedInUserStore();
    return { user };
  },
  data() {
    return {
      toastList: [],
    };
  },
  methods: {
    getTimeEllapsed(dateString) {
      const milliseconds = new Date(dateString) - Date.now();
      const seconds = milliseconds / 1000;
      const minutes = seconds / 60;
      const hours = minutes / 60;
      const days = hours / 24;

      if (seconds < 5) {
        return "Just now";
      } else if (seconds < 60) {
        return "A few seconds ago";
      } else if (minutes < 60) {
        return `${parseInt(minutes)} minutes ago`;
      } else if (hours < 24) {
        return `${parseInt(hours)} hours ago`;
      } else if (days < 7) {
        return `${parseInt(days)} days ago`;
      }

      return "";
    },
  },
  computed: {
    unreadNotes() {
      return this.user.notes.filter((note) => !note.has_been_read);
    },
  },
  watch: {
    unreadNotes: {
      deep: true,
      immediate: true,
      handler: function () {
        // wait until DOM updates before initializing new toasts
        this.$nextTick(() => {
          // get toast elements that have not been initialized
          var toastElList = [].slice
            .call(document.querySelectorAll(".toast"))
            .filter((toastEl) => !Toast.getInstance(toastEl));
          // initialize new toasts
          this.toastList = toastElList.map(function (toastEl) {
            return new Toast(toastEl, {
              delay: 10000,
            }).show();
          });
        });
      },
    },
  },
};
</script>

<style scoped>
.toast-container {
  z-index: 999;
}
.toast-body:hover {
  cursor: pointer;
}
</style>
