<template>
  <div>
    <AutoComplete
      id="shareBar"
      v-model="name"
      :dropdown="true"
      :suggestions="filteredContacts"
      :placeholder="placeholder"
      appendTo="self"
      field="name"
      class="d-flex"
      @complete="searchName($event)"
      @item-select="subscribe($event)"
      data-cy="ShareListName"
    >
      <template #item="slotProps">
        {{ slotProps.item.name }}
        {{ slotProps.item.email ? `(${slotProps.item.email})` : "" }}
        <span v-if="slotProps.item.location" class="badge bg-light text-dark"
          ><i class="pi pi-map-marker align-bottom me-1" />{{
            slotProps.item.location
          }}</span
        >
      </template>
    </AutoComplete>
    <div class="d-flex flex-wrap align-items-start">
      <span
        v-for="subscriber in subscribers"
        :key="subscriber.id"
        class="badge bg-light text-dark me-1 me-sm-2 mt-1 mt-sm-2"
        >{{ subscriber.name
        }}<button
          type="button"
          class="btn btn-sm p-0 ms-1"
          :title="'Remove ' + subscriber.name"
          @click="unsubscribe(subscriber)"
        >
          <i class="pi pi-times-circle text-muted" /></button
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareBar",
  props: {
    subscribers: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["update:subscribers"],
  data() {
    return {
      name: "",
      filteredContacts: [],
    };
  },
  methods: {
    searchName() {
      // suggest contacts that are not already subscribed who match search criteria
      this.filteredContacts = this.contacts
        .filter((contact) => {
          return (
            !this.subscribers.some(
              (subscriber) => subscriber.id == contact.id
            ) && // not already subscribed
            contact.name.toLowerCase().includes(this.name.toLowerCase()) // match search criteria
          );
        })
        .sort((contact1, contact2) => {
          const name1 = contact1.name.toLowerCase();
          const name2 = contact2.name.toLowerCase();
          return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
        }); // sort alphabetical order
    },
    subscribe(event) {
      const contact = event.value;
      const newSubscribers = [...this.subscribers, contact];
      this.name = "";
      this.$emit("update:subscribers", newSubscribers);
    },
    unsubscribe(subscriber) {
      const newSubscribers = this.subscribers.filter(
        (thisSubscriber) => thisSubscriber.id !== subscriber.id
      );
      this.$emit("update:subscribers", newSubscribers);
    },
  },
};
</script>

<style>
#shareBar .p-button {
  color: #6c757d !important;
  background: white !important;
  border-color: lightgrey !important;
  border-left: 0 !important;
}
</style>

<style scoped>
.pi-map-marker {
  font-size: 1.2em;
  color: var(--pacific-blue);
}
.pi.pi-times-circle:hover {
  color: #ff7f7f !important;
}
</style>
