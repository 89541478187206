import axios from "axios";
import { useLoggedInUserStore } from "@/store/loggedInUser";
import { openModal } from "@/services/modal";
import { closeAllModals } from "./modal";

export default function createHttp(secured = true) {
  const user = useLoggedInUserStore();
  const config = {
    validateStatus: (status) => {
      if (status === 401) {
        closeAllModals();
        openModal("sessionExpiredModal");
      }
      return status >= 200 && status < 300;
    },
  };
  if (secured) {
    config.headers = { Authorization: `bearer ${user.token}` };
  }
  return axios.create(config);
}

export function handleAbort(abortController) {
  if (abortController?.abort) {
    abortController.abort();
  }
  return new AbortController();
}
