<template>
  <div
    :class="'card mt-1 p-1 ' + isNewItem"
    :style="inCarousel ? { height: '90%' } : {}"
  >
    <div :class="['card-body', inCarousel ? 'small-card' : 'pt-0']">
      <div class="d-flex align-items-center justify-content-end">
        <div v-if="newsItem.type === 'tweet'" class="dropdown mt-1 me-2">
          <i
            class="pi pi-ellipsis-h text-dark"
            href="#"
            role="button"
            id="tweetDropdownMenuBtn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          />
          <ul class="dropdown-menu" aria-labelledby="tweetDropdownMenuBtn">
            <BaseModalButton
              class="dropdown-item text-dark"
              style="white-space: nowrap"
              modalId="blockTwitterAccount"
              @click="$emit('set-block-handle', newsItem.twitter_handle)"
            >
              <i class="pi pi-ban" /> Block
              {{ newsItem.twitter_handle }}
            </BaseModalButton>
          </ul>
        </div>
        <i
          id="hide"
          v-if="!inCarousel"
          class="float-end"
          :class="[isHidden ? 'pi pi-eye-slash' : 'pi pi-eye']"
          @click="toggleHide()"
          :title="isHidden ? 'Unhide this news item' : 'Hide this news item'"
        ></i>
      </div>
      <div class="row mb-1">
        <span
          v-if="!inCarousel && newsItem['retweeted_by']"
          class="text-muted fw-bold"
        >
          <i class="fas fa-retweet"></i>
          {{ newsItem["retweeted_by"]["name"] }} Retweeted
        </span>
        <div :class="[inCarousel ? 'col-8' : 'col-10']">
          <span class="fw-bold me-2">
            {{ newsFeedType(newsItem) }}
          </span>
        </div>
        <div :class="['text-center', inCarousel ? 'col-4' : 'col-2']">
          <span class="fw-fw-lighter feed-date fst-italic">
            {{ formattedDate }}
          </span>
        </div>
      </div>
      <div class="row p-1">
        <div class="col-10">
          <div class="fw-bold" style="color: rgb(0, 128, 163)">
            <span v-if="inCarousel">
              <router-link :title="newsItem.title" :to="{ name: 'newsfeed' }">
                {{
                  newsItem.title.length > 110
                    ? newsItem.title.slice(0, 110) + "..."
                    : newsItem.title.slice(0, 110)
                }}
              </router-link>
            </span>
            <a
              v-else-if="newsItem.type === 'pma'"
              :href="newsItem.link"
              target="_blank"
            >
              {{ newsItem.product }} Approved or Updated
            </a>
            <a v-else :href="newsItem.link" target="_blank">
              {{ newsItem.title }}
            </a>
          </div>
          <span
            v-if="newsItem.company && !inCarousel && newsItem.type !== 'recall'"
            class="feed-company d-block"
            style="color: black"
          >
            Company: {{ newsItem.company }}
          </span>
          <span
            v-if="newsItem.status && newsItem.type === 'pma' && !inCarousel"
            class="feed-company d-block"
            style="color: black"
          >
            Approval Type: {{ newsItem.status }}
          </span>
          <span
            v-if="newsItem.sponsor && !inCarousel"
            class="feed-company d-block"
            style="color: black"
          >
            Sponsor: {{ newsItem.sponsor }}
          </span>
          <span
            v-if="newsItem.journal && !inCarousel"
            class="feed-company d-block"
            style="color: black"
          >
            Journal: {{ newsItem.journal }}
          </span>
          <span
            v-if="
              newsItem.text &&
              newsItem.type !== '510k' &&
              newsItem.type !== 'physician'
            "
            class="feed-company d-block"
            style="color: black"
          >
            <div v-if="inCarousel">
              {{
                newsItem.text.length > 100
                  ? newsItem.text.slice(0, 100) + "..."
                  : newsItem.text
              }}
            </div>
            <div v-else>
              <span v-if="!readMoreActivated">
                {{ newsItem.text.slice(0, 200) }}
              </span>
              <span v-if="newsItem.text.length > 200">
                <a class="" v-if="!readMoreActivated" @click="activateReadMore">
                  (more...)
                </a>
              </span>
              <span v-if="readMoreActivated">
                {{ newsItem.text }}
                <a
                  class=""
                  v-if="readMoreActivated"
                  @click="deactivateReadMore"
                >
                  (less...)
                </a>
              </span>
            </div>
          </span>
          <span
            v-if="!newsItem.text && newsItem.type === 'publication'"
            class="feed-company d-block"
            style="color: black; font-style: italic"
          >
            No abstract available
          </span>
        </div>
        <div class="col-2 d-flex justify-content-center align-items-center">
          <span v-if="newsItem.img">
            <img :src="newsItem.img" class="resize-image" />
          </span>
          <span v-else-if="newsItem.type === 'physician'">
            <UserAvatar
              :user="{
                name: physicianName,
                id: physicianName[0].charCodeAt(),
              }"
              class="me-1"
            />
          </span>
          <span v-else-if="newsItem.type === 'publication'">
            <img
              :style="
                inCarousel
                  ? { width: '50px', height: 'auto' }
                  : { width: '100px', height: 'auto' }
              "
              src="@/assets/pubmed_logo.png"
            />
          </span>
          <span v-else-if="newsItem.type === 'trial'">
            <img
              :style="
                inCarousel
                  ? { width: '70px', height: 'auto' }
                  : { width: '100px', height: 'auto' }
              "
              src="@/assets/trials_logo.png"
            />
          </span>
          <span
            v-else-if="newsItem.type === 'pma' || newsItem.type === 'recall'"
          >
            <img
              :style="
                inCarousel
                  ? { width: '40px', height: 'auto' }
                  : { width: '80px', height: 'auto' }
              "
              src="@/assets/fda_logo.png"
            />
          </span>
          <span v-else-if="newsItem.type === 'news'">
            <BIconNewspaper :width="36" :height="36" fill="#a472ce" />
          </span>
          <span v-else-if="newsItem.type === '510k'">
            <BIconCheck :width="36" :height="36" fill="#9955e6" />
          </span>
          <span v-else-if="newsItem.type === 'tweet'">
            <BIconTwitter :width="36" :height="36" fill="#8632ff" />
          </span>
          <span v-else-if="newsItem.type === 'custom'">
            <img src="@/assets/S2NFavicon.png" class="resize-image" />
          </span>
        </div>
      </div>
      <div class="row p-1">
        <div v-if="numHospitals > 0 && newsItem.type !== 'physician'">
          <div :class="['d-none', inCarousel ? '' : 'd-md-block mt-1']">
            <BIconHospital
              :width="25"
              :height="25"
              class="rounded p-1 me-2"
              style="
                color: rgb(255, 255, 255);
                background-color: rgb(0, 128, 163);
              "
            />
            <div class="d-inline">
              <span class="fw-bold feed-hospitals text-break">{{
                hospitalList
              }}</span>
              <a
                v-if="numHospitals > 1 && showFullHospitalList === false"
                class="fw-bold fst-italic"
                @click="activateFullList"
              >
                and {{ numHospitals - 1 }}
                {{ numHospitals > 2 ? "others" : "other" }}
              </a>
              <a
                v-else-if="showFullHospitalList === true"
                @click="deactivateFullList"
              >
                (less...)
              </a>
            </div>
          </div>
        </div>
        <div v-if="newsItem.author && !inCarousel" class="d-inline">
          <i
            class="fas fa-user-md fa-lg me-2 rounded p-1"
            style="
              color: rgb(255, 255, 255);
              background-color: rgb(0, 128, 163);
            "
          ></i>
          <span
            v-if="physicianAuthor.length === 1"
            class="fw-bold feed-hospitals d-inline text-break"
            >{{ physicianAuthor[0] }}</span
          >
          <span v-else class="fw-bold feed-hospitals d-inline text-break">
            <template v-if="!readMoreActivatedAuthors">
              {{ physicianAuthor[0] }}
              <a
                class="fw-bold fst-italic text-lowercase"
                @click="activateReadMoreAuthors"
              >
                and {{ physicianAuthor.length - 1 }}
                {{ physicianAuthor.length - 1 > 1 ? "others" : "other" }}
              </a>
            </template>
            <template v-else>
              {{ physicianAuthor.join(", ") }}
              <a class="text-lowercase" @click="deactivateReadMoreAuthors">
                (less...)
              </a>
            </template>
          </span>
        </div>
        <div
          v-if="
            newsItem.type === 'physician' ||
            (newsItem.type === 'tweet' && newsItem.physicians.length > 0)
          "
        >
          <i
            class="fas fa-user-md fa-lg me-2 rounded p-1"
            style="
              color: rgb(255, 255, 255);
              background-color: rgb(0, 128, 163);
            "
          ></i>
          <router-link
            :to="{
              name: 'physician',
              params: { npi: newsItem.physicians[0].npi },
            }"
          >
            {{ physicianName }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useNewsStore } from "@/store/news";
import {
  BIconNewspaper,
  BIconCheck,
  BIconTwitter,
  BIconHospital,
} from "bootstrap-icons-vue";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import BaseModalButton from "@/components/buttons/BaseModalButton.vue";

export default {
  components: {
    BIconNewspaper,
    BIconCheck,
    BIconTwitter,
    BIconHospital,
    UserAvatar,
    BaseModalButton,
  },
  emits: ["toggle-hide", "block-handle", "set-block-handle"],
  setup() {
    const newsStore = useNewsStore();
    return { newsStore };
  },
  props: {
    newsItem: {
      type: Object,
      required: true,
    },
    lastViewed: {
      type: [Date, Number, String],
      default: Date.now(),
    },
    inCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentNewsItem: [],
      readMoreActivated: false,
      readMoreActivatedAuthors: false,
      showFullHospitalList: false,
      today: new Date(),
    };
  },
  methods: {
    blockHandle() {
      this.$emit("block-handle", this.newsItem.twitter_handle);
    },
    toggleHide() {
      if (this.isHidden) {
        this.newsStore.unhideNewsItem(this.newsItem);
      } else {
        this.newsStore.hideNewsItem(this.newsItem);
      }
      this.$emit("toggle-hide", this.newsItem);
    },
    newsFeedType(newsfeedItem) {
      if (newsfeedItem.type === "trial") {
        if (newsfeedItem.initial_date === newsfeedItem.date) {
          return "NEW Clinical Trial";
        } else {
          return "Clinical Trial Update";
        }
      } else if (newsfeedItem.type === "pma") {
        return "FDA PMA Approval";
      } else if (newsfeedItem.type === "publication") {
        if (newsfeedItem.initial_date === newsfeedItem.date) {
          return "New Publication";
        } else {
          return "Publication";
        }
      } else if (newsfeedItem.type === "recall") {
        return "FDA Recall";
      } else if (newsfeedItem.type === "510k") {
        return "FDA 510(K) Clearance";
      } else if (newsfeedItem.type === "news") {
        return "Company News";
      } else if (newsfeedItem.type === "tweet") {
        return "Twitter Activity";
      } else if (newsfeedItem.type === "physician") {
        return "Physician Information Change";
      } else if (newsfeedItem.type === "custom") {
        return newsfeedItem.subject;
      }
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    deactivateReadMore() {
      this.readMoreActivated = false;
    },
    activateReadMoreAuthors() {
      this.readMoreActivatedAuthors = true;
    },
    deactivateReadMoreAuthors() {
      this.readMoreActivatedAuthors = false;
    },
    activateFullList() {
      this.showFullHospitalList = true;
    },
    deactivateFullList() {
      this.showFullHospitalList = false;
    },
  },
  computed: {
    isHidden() {
      return this.newsItem.is_hidden;
    },
    numHospitals() {
      return this.newsItem.hospitals?.length ?? 0;
    },
    formattedDate() {
      if (!this.newsItem.date) {
        return "";
      }

      return new Date(this.newsItem.date).toLocaleString("en-US", {
        year: "numeric",
        day: "numeric",
        month: "long",
      });
    },
    todaysFormattedDate() {
      if (!this.today) {
        return "";
      }

      return new Date(this.today).toLocaleString("en-US", {
        year: "numeric",
        day: "numeric",
        month: "long",
      });
    },
    isNewItem() {
      if (
        (this.lastViewed === null) |
        (Date.parse(this.newsItem.date) > Date.parse(this.lastViewed))
      ) {
        return "new-news-item";
      } else {
        return "old-news-item";
      }
    },
    hospitalList() {
      if (this.newsItem.hospitals?.length === 0 ?? true) {
        return "";
      }
      if (this.showFullHospitalList === true) {
        const hospitals = [];
        for (let i = 0; i < this.numHospitals; i++) {
          hospitals.push(this.newsItem.hospitals[i].name);
        }
        return hospitals.join(", ");
      } else {
        return this.newsItem.hospitals[0].name;
      }
    },
    physicianAuthor() {
      const authors = [];
      this.newsItem.authors.forEach((author) => {
        authors.push(author.split(",")[0]);
      });

      return authors;
    },
    physicianName() {
      const name = this.newsItem.physicians[0].name.split(" ");
      for (let i = 0; i < name.length; i++) {
        name[i] = name[i][0].toUpperCase() + name[i].substr(1).toLowerCase();
      }

      return name.join(" ");
    },
  },
};
</script>

<style scoped>
.card {
  font-size: 90%;
}
.small-card {
  font-size: 85%;
}

.feed-date {
  font-size: 0.8rem;
}
.feed-title {
  font-size: 1rem !important;
}
.small-card .feed-title {
  font-size: 115% !important;
}
.feed-company {
  color: #124e70;
}
.feed-hospitals {
  text-transform: capitalize;
}
.resize-image {
  max-width: 75%;
  max-height: 75%;
  height: auto;
  width: auto;
}
.resize-inline-image {
  max-width: 45%;
  max-height: 45%;
  height: auto;
  width: auto;
}
.new-news-item {
  background: hsl(214, 85%, 95%);
}

#hide:hover {
  color: gray;
  cursor: pointer;
}
</style>
