import createHttp from "@/services/http";

export async function forgotPassword(model) {
  const http = createHttp(false); // unsecured
  const result = await http.post(
    `${import.meta.env.VITE_FLASK_URL}/api/forgot_password`,
    model
  );
  return result.data;
}

export async function verifyPasswordReset(model) {
  const http = createHttp(false); // unsecured
  const result = await http.post(
    `${import.meta.env.VITE_FLASK_URL}/api/reset_verified`,
    model
  );

  if (result.status !== 200) {
    throw new Error(result);
  }
  return result.data;
}

export async function getUserInfo() {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/user/info`;
  const result = await createHttp().get(path);
  return result.data;
}

export async function getUserContacts(includeMe = false) {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/user/contacts${
      includeMe ? `?include-me=true` : ``
    }`
  );
  return response.data;
}

export async function getBlockedTwitterAccounts() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/user/blocked_twitter_accounts`
  );
  return response.data;
}

export async function profileSearch(profileId, profileType) {
  const response = await createHttp().post(
    `${import.meta.env.VITE_FLASK_URL}/api/user/profile_search`,
    { profile_id: profileId, profile_type: profileType }
  );
  return response.data;
}

export async function profileView(profileId, profileType) {
  const response = await createHttp().post(
    `${import.meta.env.VITE_FLASK_URL}/api/user/profile_view`,
    { profile_id: profileId, profile_type: profileType }
  );
  return response.data;
}
