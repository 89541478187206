import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import * as Sentry from "@sentry/vue";
import * as FullStory from "@fullstory/browser";
import { BrowserTracing } from "@sentry/tracing";
import VueGtag from "vue-gtag";

import PrimeVue from "primevue/config";
import Checkbox from "primevue/checkbox";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import Chart from "primevue/chart";
import AutoComplete from "primevue/autocomplete";
import Dropdown from "primevue/dropdown";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import OverlayPanel from "primevue/overlaypanel";
import ListBox from "primevue/listbox";
import MultiSelect from "primevue/multiselect";
import PrimevueDialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import PrimevueDropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import PrimevueButton from "primevue/button";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Panel from "primevue/panel";
import Chip from "primevue/chip";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import { useLoggedInUserStore } from "@/store/loggedInUser";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import debounce from "./directives/debounce";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);

Sentry.init({
  app,
  dsn: "https://5a36adffc2f54897959d6e2e9517204b@o1077102.ingest.sentry.io/6276026",
  enabled: window.location.hostname !== "localhost",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "repsignal.s2nhealth.com",
        "dev-repsignal.s2nhealth.com",
        "test-repsignal.s2nhealth.com",
        /^\//,
      ],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

FullStory.init({ orgId: "o-1P98FT-na1" });
app.config.globalProperties.$FullStory = FullStory;
router.beforeEach((to) => {
  const user = useLoggedInUserStore();
  if (user?.user_id && !to.name.includes(["login", "logout"])) {
    //Our minimun user ID is 1 and the minimum for FS is 2. This ensures that the user ID will never be just 1
    FullStory.identify("0" + user.user_id, {
      displayName: user.name,
      user_type: user.user_type,
      company: user.company,
    });
  } else {
    FullStory.anonymize();
  }
});

app.use(router);
app.use(PrimeVue);
app.use(pinia);
app.use(ToastService);
app.use(ConfirmationService);

app.use(
  VueGtag,
  {
    appName: "RepSignal",
    pageTrackerScreenviewEnabled: true,
    config: {
      id: "290277714",
    },
  },
  router
);

app.component("Checkbox", Checkbox);
app.component("DataTable", DataTable);
app.component("DataTableColumn", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("OverlayPanel", OverlayPanel);
app.component("ListBox", ListBox);
app.component("MultiSelect", MultiSelect);
app.component("PrimevueDialog", PrimevueDialog);
app.component("PrimevueDropdown", PrimevueDropdown);
app.component("PrimevueButton", PrimevueButton);
app.component("InputText", InputText);
app.component("Panel", Panel);
app.component("Chip", Chip);
app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("ConfirmDialog", ConfirmDialog);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Row", Row);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Chart", Chart);
app.component("AutoComplete", AutoComplete);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Dropdown", Dropdown);

app.directive("debounce", (el, binding) => debounce(el, binding));
app.directive("tooltip", Tooltip);

app.use(BootstrapIconsPlugin);

app.mount("#app");
