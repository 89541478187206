import { onUnmounted, ref } from "vue";

// https://getbootstrap.com/docs/5.2/layout/breakpoints
const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export default function isMobile(breakpoint = "sm") {
  const width = BREAKPOINTS[breakpoint];
  if (!width) {
    throw Error(`Invalid breakpoint "${breakpoint}"`);
  }

  window.addEventListener("resize", checkWidth);
  const isMobileDevice = ref(window.innerWidth < width);
  onUnmounted(() => window.removeEventListener("resize", checkWidth()));

  function checkWidth() {
    isMobileDevice.value = window.innerWidth < width;
  }

  return { isMobileDevice };
}
