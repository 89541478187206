import { Modal } from "bootstrap";

// Prevent BaseModal backdrop from persisting when navigating pages with back button.
export function removeModalBackdrop() {
  const modalBackdrops = document.getElementsByClassName("modal-backdrop");
  for (const modalBackdrop of modalBackdrops) {
    modalBackdrop.remove();
  }
}

export function openModal(id) {
  const element = document.getElementById(id);
  if (!element) return;
  const modal = Modal.getOrCreateInstance(element);
  modal.show();
}

export function closeAllModals() {
  const modalElements = document.getElementsByClassName("modal");
  for (const modalElement of modalElements) {
    const modal = Modal.getOrCreateInstance(modalElement);
    modal.hide();
  }
}
