<template>
  <div class="dropdown me-2">
    <button
      class="btn btn-sm blue-green-button"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <div class="d-flex small">
        <div class="pe-2 border-end fw-bold">
          <strong>Module</strong>
        </div>
        <div class="d-inline-block text-truncate ps-2 text-start me-1">
          {{ activeModule.name }}
        </div>
        <i class="pi pi-angle-down"></i>
      </div>
    </button>
    <div class="dropdown-menu dropdown-menu-start animate slideIn scrollable">
      <a
        class="dropdown-item small"
        v-for="item in procMods"
        :key="item.id"
        href="#"
        @click="$emit('module-switch', item.id)"
      >
        {{ item.name }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcedureModuleSwitcher",
  props: {
    procMods: {
      type: Array,
      required: true,
    },
  },
  emits: ["module-switch"],
  computed: {
    activeModule() {
      return this.procMods.find((procMod) => procMod.active);
    },
  },
};
</script>

<style scoped>
.blue-green-button,
.blue-green-button.show,
.blue-green-button:active,
.blue-green-button:focus,
.blue-green-button:hover,
.btn:first-child:active {
  background-color: white;
  color: hsl(180, 100%, 32%);
  line-height: 1em;
  border-radius: 10px;
  border-color: hsl(180, 100%, 32%);
  transition-duration: 0.2s;
}
.blue-green-button:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
.blue-green-button:first-child:active {
  background-color: hsl(180, 100%, 29%);
}

a.dropdown-item {
  color: black !important;
}
a.dropdown-item:active {
  background-color: white;
}
@media screen and (max-width: 1080px) {
  .blue-green-button {
    max-width: 14rem;
  }
}
@media screen and (max-width: 480px) {
  .blue-green-button {
    max-width: 9rem;
  }
}
.scrollable {
  max-height: 200px;
  overflow-y: auto;
}
</style>
