import createHttp from "@/services/http";

export async function getNotes() {
  const response = await createHttp().get(
    `${import.meta.env.VITE_FLASK_URL}/api/notes`
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}

export async function addNote(noteData) {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/notes/add`;
  const response = await createHttp().post(path, noteData);
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}

export async function editNote(noteId, patchData) {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/note/${noteId}/edit`;
  const response = await createHttp().patch(path, patchData);
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}

export async function markNoteAsRead(noteId) {
  const response = await createHttp().patch(
    `${import.meta.env.VITE_FLASK_URL}/api/note/${noteId}/mark_as_read`
  );
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}

export async function deleteNote(noteId) {
  const path = `${import.meta.env.VITE_FLASK_URL}/api/note/${noteId}/delete`;

  const response = await createHttp().delete(path);
  if (response.status !== 200) {
    throw new Error(response);
  }
  return response.data;
}
