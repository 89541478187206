<template>
  <nav
    class="navbar navbar-expand-xl navbar-light fixed-top bg-light border-0 shadow-sm"
  >
    <div class="container-fluid">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'home' }" class="navbar-brand"
          ><img src="@/assets/RepSignalLogo.png" height="43" alt=""
        /></router-link>
        <ProcedureModuleSwitcher
          v-if="procMods.length != 0"
          :procMods="procMods"
          @module-switch="(procModId) => $emit('module-switch', procModId)"
        />
      </div>
      <TutorialPopover
        v-if="!isMobileDevice"
        feature="quickSearch"
        header="NEW Quick Search"
        :showIfNeverDismissed="!isMobileDevice"
        :style="{
          width: 'inherit',
          maxWidth: '580px',
        }"
      >
        <template #anchor>
          <UniversalSearchBar />
        </template>
        <template #content>
          <div style="max-width: 500px">
            Use this search bar to quickly find physicians and facilities in
            RepSignal. This search bar lives on RepSignal wherever you go.
          </div>
        </template>
      </TutorialPopover>
      <button
        class="navbar-toggler ms-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav mb-2 mb-lg-0">
          <div class="navbar-nav">
            <li class="nav-item">
              <router-link :to="{ name: 'home' }" class="nav-link">
                Home
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'explorer' }" class="nav-link">
                Explorer
              </router-link>
            </li>
            <li v-if="isLeader" class="nav-item">
              <router-link :to="{ name: 'region_manager' }" class="nav-link">
                Territories
              </router-link>
            </li>
            <li v-if="isLeader && hasMarketSummaryAccess" class="nav-item">
              <router-link
                :to="{ name: 'marketSummary' }"
                class="nav-link d-flex flex-nowrap align-items-center"
              >
                Markets
                <span class="badge beta-badge ms-1">BETA</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'lists' }" class="nav-link">
                Lists
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'newsfeed' }"
                class="nav-link d-flex flex-nowrap"
              >
                News
                <span v-if="news.showIndicator"
                  ><i
                    class="pi pi-circle-on align-text-top"
                    style="font-size: 0.55rem; color: #31bfd8"
                  ></i>
                </span>
              </router-link>
            </li>
          </div>

          <li class="nav-item">
            <BaseModalButton
              class="btn nav-link position-relative notes-button"
              title="Intel Book"
              modalId="notesModal"
            >
              <i class="pi pi-book text-black" />
              <span
                v-show="unreadNotes.length !== 0"
                class="notification position-absolute top-25 start-75 translate-middle border border-light rounded-circle bg-info"
                title="New notes have been shared with you"
              >
                <span class="visually-hidden">new notes</span>
              </span>
            </BaseModalButton>
          </li>

          <li class="nav-item animate">
            <a
              class="nav-link"
              :href="GENERAL_REPSIGNAL_HELP_LINK"
              target="_blank"
              ><i class="pi pi-question-circle"></i
            ></a>
          </li>
          <li class="nav-item animate nav-li dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-cy="NavCogDropdown"
            >
              <i class="pi pi-cog"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <router-link
                  :to="{ name: 'account' }"
                  class="nav-link text-dark"
                  >My Account</router-link
                >
              </li>
              <li class="nav-item" v-if="user.is_admin">
                <a class="nav-link" :href="getEnvUrl + '/admin/login'">
                  Admin
                </a>
              </li>
              <li>
                <router-link
                  :to="{ name: 'logout' }"
                  class="nav-link text-dark"
                  data-cy="NavLogoutButton"
                  >Logout</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <UniversalSearchBar class="px-3 py-1" v-if="isMobileDevice" />
  </nav>
</template>

<script>
import { useLoggedInUserStore } from "@/store/loggedInUser";
import { useNewsStore } from "@/store/news";
import ProcedureModuleSwitcher from "./ProcedureModuleSwitcher.vue";
import BaseModalButton from "@/components/buttons/BaseModalButton.vue";
import TutorialPopover from "@/components/ui/TutorialPopover.vue";
import isMobile from "@/composables/datatable/isMobile";
import { GENERAL_REPSIGNAL_HELP_LINK } from "@/services/infoTooltips";
import UniversalSearchBar from "@/components/ui/UniversalSearch/UniversalSearchBar.vue";

export default {
  components: {
    ProcedureModuleSwitcher,
    BaseModalButton,
    TutorialPopover,
    UniversalSearchBar,
  },
  props: {
    procMods: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["module-switch"],
  setup() {
    const user = useLoggedInUserStore();
    const news = useNewsStore();
    const { isMobileDevice } = isMobile("lg");
    return {
      user,
      news,
      isMobileDevice,
      GENERAL_REPSIGNAL_HELP_LINK,
    };
  },
  data() {
    return {
      hasMarketSummaryAccess: false,
    };
  },
  computed: {
    getEnvUrl() {
      return import.meta.env.VITE_FLASK_URL;
    },
    unreadNotes() {
      return this.user.notes.filter((note) => !note.has_been_read);
    },
    isLeader() {
      return this.user.isLeader;
    },
  },
  async created() {
    this.hasMarketSummaryAccess = await this.user.getFeatureFlag(
      "marketSummary"
    );
  },
};
</script>

<style scoped>
a {
  color: black !important;
}
.notification {
  padding: 0.4em;
}
.router-link-exact-active {
  font-weight: 600;
}
.notes-button {
  border: none;
  color: black;
  transition: color 0.15s;
}
.notes-button:focus {
  box-shadow: none;
}
.notes-button i {
  transition: color 0.15s;
}
.notes-button:hover i {
  color: #124e70 !important;
}
.main-button-inverse {
  --bs-btn-font-size: 0.875rem;
}

.navbar-collapse {
  flex-grow: 0;
}
</style>
