<template>
  <BaseModal
    modalId="sessionExpiredModal"
    title="Session Expired"
    @close="logout"
  >
    <template #body
      >Your session has expired. Please log in to continue.</template
    >
    <template #footer>
      <button
        type="submit"
        class="btn pacific-button fw-bold"
        data-bs-dismiss="modal"
        @click="logout"
      >
        Okay
      </button>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "./BaseModal.vue";

export default {
  name: "SessionExpiredModal",
  components: { BaseModal },
  methods: {
    logout() {
      this.$router.push({ name: "logout" });
    },
  },
};
</script>
