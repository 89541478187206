<template>
  <footer class="footer mt-5">
    <div class="container text-center">
      <p>S2N Health * Boston - Denver *</p>
    </div>
  </footer>
</template>

<style scoped>
p {
  font-size: 13px;
}
.footer {
  width: 100vw;
  /* Set the fixed height of the footer here */
  height: 40px;
  line-height: 40px; /* Vertically center the text there */
  background-color: #0080a3;
  color: white;
}
</style>
