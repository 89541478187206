<!--
  A modal with a static backdrop.
  Use a BaseModalButton to activate the modal.
-->

<template>
  <div
    class="modal fade"
    :id="modalId"
    :data-cy="modalId"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="modalId + 'Label'"
    aria-hidden="true"
    @click.stop
  >
    <div :class="modalDialogClass">
      <div class="modal-content">
        <div class="modal-header">
          <h1
            class="modal-title text-capitalize text-white fs-5"
            :id="modalId + 'Label'"
          >
            {{ title }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :data-cy="`${modalId}CloseButton`"
            @click="$emit('close')"
          ></button>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" v-if="hasFooterSlot">
          <slot name="footer"></slot>
          <!--
            To make a button close this modal, add the following attribute to the button:
              data-bs-dismiss="modal"
            Example:
              <button type="button" class="btn btn-light" data-bs-dismiss="modal">
                Close
              </button>
          -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MODAL_SIZES = ["sm", "lg", "xl"];
const MODAL_FULLSCREEN_SIZES = ["sm", "md", "lg", "xl", "xxl"];

export default {
  name: "BaseModal",
  emits: ["close", "open"],
  props: {
    modalId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    size: {
      // Values 'sm', 'lg', or 'xl' will cause modal to be that size.
      // Any other value will use default size (sort of like 'md').
      // https://getbootstrap.com/docs/5.2/components/modal/#optional-sizes
      type: String,
      default: "",
    },
    fullscreenSize: {
      // Accepts the following values:
      // - 'sm', 'md', 'lg', 'xl', or 'xxl' specify the minimum width
      //   at which the modal will become fullscreen
      // - 'always' will cause the modal to always be fullscreen
      // - 'never' will cause the modal to never be fullscreen
      //   (not recommended for responsive design)
      // Default is 'md'.
      // https://getbootstrap.com/docs/5.2/components/modal/#fullscreen-modal
      type: String,
      default: "md",
    },
    centered: {
      // Vertical centering
      type: Boolean,
      default: false,
    },
    scrollable: {
      // Scrollable modal
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    var modal = document.getElementById(this.modalId);
    modal.addEventListener("show.bs.modal", this.modalShowHandler);
  },
  beforeUnmount() {
    var modal = document.getElementById(this.modalId);
    modal.removeEventListener("show.bs.modal", this.modalShowHandler);
  },
  methods: {
    modalShowHandler() {
      this.$emit("open");
    },
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    sizeClass() {
      return MODAL_SIZES.includes(this.size) ? `modal-${this.size}` : "";
    },
    fullscreenSizeClass() {
      if (MODAL_FULLSCREEN_SIZES.includes(this.fullscreenSize)) {
        return `modal-fullscreen-${this.fullscreenSize}-down`;
      }
      if (this.fullscreenSize == "always") {
        return "modal-fullscreen";
      }
      if (this.fullscreenSize == "never") {
        return "";
      }
      return "modal-fullscreen-md-down";
    },
    modalDialogClass() {
      let className = "modal-dialog";
      if (this.centered) {
        className += " modal-dialog-centered";
      }
      if (this.scrollable) {
        className += " modal-dialog-scrollable";
      }
      if (this.sizeClass) {
        className += ` ${this.sizeClass}`;
      }
      if (this.fullscreenSizeClass) {
        className += ` ${this.fullscreenSizeClass}`;
      }
      return className;
    },
  },
};
</script>

<style scoped>
.modal-header {
  background-color: var(--S2NMainBlue);
}
</style>
