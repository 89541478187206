<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
      fill="none"
    />
    <path
      :style="`fill: ${color}`"
      d="M72.5625 63.7031C72.5625 56.9531 68.4844 52.5938 62.4375 50.3438C62.7188 51.4688 62.7187 52.5937 62.7187 53.7187V57.6562C66.0937 58.2187 68.625 60.75 68.625 63.7031V69.75C68.625 70.3125 68.625 71.1562 68.0625 71.7187C67.6406 72.1406 67.0781 72.2812 66.375 72.2812C65.5312 72.2812 64.9687 71.5781 64.9687 70.875C64.9687 70.3125 65.25 69.8906 65.8125 69.6094V63.5625C65.8125 61.7344 63.7031 60.3281 61.3125 60.3281C58.9219 60.3281 56.8125 61.875 56.8125 63.5625V69.6094C57.2344 69.8906 57.6562 70.3125 57.6562 70.875C57.6562 71.7188 57.0938 72.2812 56.25 72.2812C55.5469 72.2812 54.9844 72.1406 54.5625 71.7187C54 71.0156 54 70.3125 54 69.75V63.7031C54 60.75 56.5312 58.2187 59.9062 57.6562V53.7187C59.9062 52.1719 59.625 50.7656 59.2031 49.3594C58.9219 49.2187 58.5 49.2187 58.2188 49.0781L50.9062 57.9375L43.7344 49.2187C43.1719 49.3594 42.6094 49.5 42.0469 49.6406C41.625 50.9063 41.4844 52.3125 41.4844 53.8594V63C42.8906 63.5625 43.875 64.9688 43.875 66.5156C43.875 68.625 42.1875 70.3125 40.0781 70.3125C37.9688 70.3125 36.2812 68.625 36.2812 66.5156C36.2812 64.9688 37.2656 63.5625 38.6719 63V53.8594C38.6719 52.7344 38.8125 51.75 38.9531 50.7656C33.3281 53.1562 29.5312 57.375 29.5312 63.8438V73.9687V76.5C29.5312 77.9062 30.6562 79.0312 32.0625 79.0312H70.0313C71.4375 79.0312 72.5625 77.9062 72.5625 76.5V74.1094V63.7031Z"
    />
    <path
      :style="`fill: ${color}`"
      d="M50.9062 46.5469C57.5156 46.5469 62.8594 41.2031 62.8594 34.5938C62.8594 27.9844 57.5156 22.6406 50.9062 22.6406C44.2969 22.6406 38.9531 27.9844 38.9531 34.5938C38.9531 41.2031 44.4375 46.5469 50.9062 46.5469Z"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: String,
  },
};
</script>
