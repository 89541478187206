import { defineStore } from "pinia";
import { loadSortWorker } from "@/services/workers.js";
import {
  getTweets,
  getTrials,
  getPmas,
  getRecalls,
  getFiveTenKs,
  getPublications,
  getNews,
  getCustomNews,
  getPhysicianUpdates,
  updateLastViewed,
  hideNewsItem,
  unhideNewsItem,
} from "@/services/apiCalls/newsfeed";

const sortWorker = loadSortWorker();

export const useNewsStore = defineStore({
  id: "news",
  state: () => ({
    allNews: [],
    trials: [],
    pmas: [],
    publications: [],
    recalls: [],
    fiveTenKs: [],
    news: [],
    tweets: [],
    physicianUpdates: [],
    customNews: [],
    lastViewedNewsfeed: null,
    fetchedNews: false,
    showIndicator: false,
  }),
  persist: true,
  getters: {
    latestNewsDate() {
      if (this.allNews.length === 0) {
        return null;
      }
      return Date.parse(this.allNews[0].date);
    },
  },
  actions: {
    async getTrials() {
      try {
        this.trials = await getTrials();
      } catch (error) {
        console.log(error);
      }
    },
    async getPmas() {
      try {
        this.pmas = await getPmas();
      } catch (error) {
        console.log(error);
      }
    },
    async getPublications() {
      try {
        this.publications = await getPublications();
      } catch (error) {
        console.log(error);
      }
    },
    async getRecalls() {
      try {
        this.recalls = await getRecalls();
      } catch (error) {
        console.log(error);
      }
    },
    async getFiveTenKs() {
      try {
        this.fiveTenKs = await getFiveTenKs();
      } catch (error) {
        console.log(error);
      }
    },
    async getNews() {
      try {
        this.news = await getNews();
      } catch (error) {
        console.log(error);
      }
    },
    async getTweets() {
      try {
        this.tweets = await getTweets();
      } catch (error) {
        console.log(error);
      }
    },
    async getPhysicianUpdates() {
      try {
        this.physicianUpdates = await getPhysicianUpdates();
      } catch (error) {
        console.log(error);
      }
    },
    async getCustomNews() {
      try {
        this.customNews = await getCustomNews();
      } catch (error) {
        console.log(error);
      }
    },
    async hideNewsItem(newsItem) {
      try {
        newsItem.is_hidden = true;
        await hideNewsItem(newsItem.id);
      } catch (error) {
        console.log(error);
      }
    },
    async unhideNewsItem(newsItem) {
      try {
        newsItem.is_hidden = false;
        await unhideNewsItem(newsItem.id);
      } catch (error) {
        console.log(error);
      }
    },
    async updateLastViewed() {
      try {
        await updateLastViewed();
        this.lastViewedNewsfeed = Date.now();
        this.showIndicator = false;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllNews(maxArticles = 500) {
      this.fetchedNews = false;

      await Promise.all([
        this.getTrials(),
        this.getPmas(),
        this.getPublications(),
        this.getRecalls(),
        this.getFiveTenKs(),
        this.getNews(),
        this.getTweets(),
        this.getPhysicianUpdates(),
        this.getCustomNews(),
      ]);

      const newsItems = [
        ...this.trials,
        ...this.pmas,
        ...this.publications,
        ...this.recalls,
        ...this.fiveTenKs,
        ...this.news,
        ...this.tweets,
        ...this.physicianUpdates,
        ...this.customNews,
      ];

      // Sort news most-recent-first in a separate worker thread:

      sortWorker.postMessage({
        method: "date",
        args: JSON.parse(JSON.stringify(newsItems)), // https://stackoverflow.com/questions/42376464/uncaught-domexception-failed-to-execute-postmessage-on-window-an-object-co
      });

      sortWorker.onmessage = (event) => {
        if (event.data.key === "working") {
          return;
        }

        const sortedNews = event.data.value;
        this.allNews = sortedNews.slice(0, maxArticles);
        this.fetchedNews = true;
        this.showIndicator =
          this.latestNewsDate > Date.parse(this.lastViewedNewsfeed);
      };
    },
  },
});
