export function loadSortWorker() {
  return loadWorker(sortWorker);
}
export function loadFilterNewsWorker() {
  return loadWorker(filterNewsWorker);
}

// https://stackoverflow.com/questions/49171791/web-worker-onmessage-uncaught-syntaxerror-unexpected-token
function loadWorker(worker) {
  const code = worker.toString();
  const blob = new Blob(["(" + code + ")()"]);
  return new Worker(URL.createObjectURL(blob));
}

function sortWorker() {
  const by = {
    date: (item1, item2) => Date.parse(item2.date) - Date.parse(item1.date),
  };

  addEventListener("message", (event) => {
    postMessage({ key: "working", value: true });

    const items = event.data.args;
    items.sort(by[event.data.method]);

    postMessage({
      key: "sorted",
      value: items,
    });

    postMessage({ key: "working", value: false });
  });
}

function filterNewsWorker() {
  addEventListener("message", (event) => {
    postMessage({ key: "working", value: true });

    const newsItems = event.data.args;
    const filterOptions = event.data.options;

    const filteredNewsItems = newsItems.filter((item) => {
      return passesFilter(item, filterOptions);
    });

    postMessage({
      key: "filtered",
      value: filteredNewsItems,
    });

    postMessage({ key: "working", value: false });
  });

  function passesFilter(item, filterOptions) {
    const passesTypeFilter = checkTypeFilter(
      item.type,
      filterOptions.typesShown
    );

    return passesTypeFilter;
  }

  function checkTypeFilter(type, typesShown) {
    return typesShown.includes(type);
  }
}
