<template>
  <router-link
    :to="{
      name: 'physician',
      params: { npi: this.physician.npi },
    }"
    class="d-flex align-items-center nunito"
    :style="{ 'pointer-events': 'auto', color: 'inherit !important' }"
    @click="handleNavigate"
  >
    <img
      src="@/assets/physicianSearchIcon.svg"
      class="me-2"
      alt="Physician Search Icon"
      style="height: 35px"
    />
    <div>
      <div class="physician-name">
        {{ physician.name }}
      </div>

      <div class="d-flex align-items-center text">
        <i class="pi pi-map-marker me-1" style="font-size: 1rem" />
        <span class="me-3">{{ this.physicianAddress }} </span>
        {{ this.physician.primary_specialty }}
      </div>
    </div>
  </router-link>
</template>

<script>
import { formatTitleCase } from "@/services/textFormatters";
import { profileSearch } from "@/services/apiCalls/user";

export default {
  name: "PhysicianTile",
  props: {
    physician: {
      type: Object,
      required: true,
    },
  },
  computed: {
    physicianAddress() {
      const city = formatTitleCase(this.physician.city);
      return `${city}, ${this.physician.state}`;
    },
  },
  methods: {
    handleNavigate() {
      profileSearch(this.physician.npi, this.physician.item_type);
    },
  },
};
</script>

<style scoped>
.physician-name {
  color: var(--S2NMainBlue) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.text {
  color: var(--S2NMedGrey);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.nunito {
  font-family: nunito-sans;
}
</style>
